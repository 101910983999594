import React from "react";
import { Typography, Box, Container } from "@mui/material";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";

const AboutPage = () => {
  return (
    <Layout>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Clinical Trials
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            Helping others by contributing to medical research
          </Typography>

          <Typography
            variant="body1"
            align="center"
            color="text.secondary"
            paragraph
          >
            A clinical trial, also called a clinical research study, is a
            carefully designed scientific evaluation of an investigational
            medication or treatment. Clinical trials are conducted by doctors
            and researchers. They help to answer important medical questions,
            such as:
          </Typography>
        </Container>
      </Box>
    </Layout>
  );
};

export default AboutPage;
export { Head } from "../head";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
